import { useEffect } from "react";

export default function AboutMe() {

  useEffect(() => {
    document.title = "Maximilian Asam - Über mich"
  }, []);

  return <div className="about-me">
    <h1>Über mich</h1>
    <hr />
    <p>
      I write code for fun and for work!
    </p>
    <p>
      Hello World! Mein Name ist Maximilian und ich bin 24 Jahre alt. Ich programmiere seit ich 13 Jahre alt bin (auch wenn ich mit 13 definitiv noch nicht gut drin war) und es ist neben meiner Leidenschaft auch zu meinem Beruf geworden.
    </p>
    <p>
      <b>In aller Wahrscheinlichkeit sehen sie diesen Inhalt nur deshalb, weil etwas mit der Authentification schief ging.</b> <br />
      <b>Sie können dennoch den Lebenslauf einsehen, aber einige Details wie meine Adresse oder das Anschreiben an Sie werden hier aus Datenschutzgründen nicht angezeigt.</b>
    </p>
  </div>;
}