import { ExperienceData } from "../../api/company";
import ExternalLink from "../ExternalLink";

export default function ExperienceItem(props: { data: ExperienceData }) {
    let linkOrNot;
    if (props.data.link) {
        linkOrNot = <ExternalLink href={props.data.link}>{props.data.location}</ExternalLink>;
    } else {
        linkOrNot = props.data.location;
    }
    return <div className="item">
        <div className="time">
            {props.data.time}
        </div>
        <h3>{props.data.title} <span className="soft">{props.data.at}</span> {linkOrNot}</h3>
        <ul>
            {props.data.bullets.map((bullet, key) => {
                return <li key={key}>{bullet}</li>;
            })}
        </ul>
    </div>;
}