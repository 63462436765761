const baseUrl = 'https://asam.dev/api/'

async function get(endpoint: string, auth: string = "") {
    auth = auth ? `?auth=${auth}` : ''
    return fetch(baseUrl + endpoint + auth, { method: 'GET' })
        .then(response => response.json())
}

async function post(endpoint: string, auth: string = "") {
    auth = auth ? `?auth=${auth}` : ''
    return fetch(baseUrl + endpoint + auth, { method: 'POST' })
        .then(response => response.json())
}

export { get, post , baseUrl};