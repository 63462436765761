import './styles/App.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Wrapper from './Wrapper';
import AboutMe from './pages/AboutMe';
import Intro from './pages/Intro';
import CV from './pages/CV';
import Contact from './pages/Contact';
import { useEffect, useState } from 'react';
import ColorMode from './ColorMode';
import requestAll, { CVData } from './api/company';
import LoadingScreen from './components/general/LoadingScreen';
import ErrorMessage from './components/ErrorMessage';

function getDefaultColorMode() {
  const stored = sessionStorage.getItem('colorMode');
  if (stored) {
    return stored as ColorMode;
  }
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    return ColorMode.DARK;
  }
  return ColorMode.LIGHT;
}

export default function App() {
  const [colorMode, setColorMode] = useState<ColorMode>(getDefaultColorMode);

  useEffect(() => {
    sessionStorage.setItem('colorMode', colorMode);
  }, [colorMode]);

  const [intro, setIntro] = useState<string>("");
  const [cvData, setcvData] = useState<CVData | null>(null);
  const [finishedLoading, setFinishedLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    requestAll(setFinishedLoading, setError, setIntro, setcvData);
  }, []);

  return (
    <div className={"App " + colorMode}>
      <LoadingScreen visible={!finishedLoading} />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Wrapper colorMode={colorMode} setColorMode={setColorMode} image={cvData?.image} pdf={cvData?.pdf}/>}>
            <Route index element={intro ? <Intro content={intro} /> : <AboutMe />} />
            <Route path="cv" element={<CV data={cvData} />} />
            <Route path="contact" element={<Contact address={cvData?.address}/>} />
          </Route>
        </Routes>
      </BrowserRouter>
      <ErrorMessage error={error} />
    </div>
  );
}
