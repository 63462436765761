import { Link, useLocation } from "react-router-dom";
import "../../styles/Sidebar.scss";
import face from "../../assets/lofi_face.webp";
import backgroundLight from "../../assets/new-york-day.webp";
import backgroundDark from "../../assets/new-york-night-no-moon.webp";
import { MdChevronRight } from "react-icons/md"
import { useEffect, useState } from "react";

function Sidebar(props: { image: string | undefined }) {
    const [expanded, setExpanded] = useState(false);

    const {search} = useLocation();   

    // Preload background images
    useEffect(() => {
        const img = [new Image(), new Image()];
        img[0].src = backgroundLight;
        img[1].src = backgroundDark;
    }, []);

    return <div className={"sidebar " + (expanded ? "expanded" : "minimized")}>
        <img className="circle" src={props.image?"https://asam.dev"+props.image:face} alt="Avatar" />
        <h2>Maximilian Asam</h2>
        <sub>Software Engineer / Student</sub>
        <nav>
            <Link to={`/${search}`} onClick={() => setExpanded(false)}>{props.image?"Bewerbung":"Über mich"}</Link>
            <Link to={`/cv${search}`} onClick={() => setExpanded(false)}>Lebenslauf</Link>
            <Link to={`/contact${search}`} onClick={() => setExpanded(false)}>Kontakt</Link>
        </nav>
        <div className="expand" onClick={() => setExpanded(!expanded)}>
            <div className={"chevrons "+(expanded ? "rotate" : "")}>
                <MdChevronRight  />
                <MdChevronRight  />
            </div>
        </div>
    </div>;
}

export default Sidebar;