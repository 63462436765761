import { get } from "./local";

class CVData {
    image: string = "";
    address: string = "";
    pdf: string = "";
    skills: {[name: string]: Array<string>} = {};
    experience: Array<ExperienceData> = [];
    education: Array<ExperienceData> = [];
}

class ExperienceData{
    time:string = "";
    title:string = "";
    at:string = "";
    location:string = "";
    link:string = "";
    bullets:Array<string> = [];
}

function requestAll(
    setFinishedLoading: (finished: boolean) => void, 
    setError: (error: string) => void, 
    setIntro: (intro: string) => void, 
    setCVData: (value: CVData) => void,) {

    const auth = getAuthFromUrl();
    if (auth) {
        get("checkPassword", auth).then((response) => {
            if (response.error) {
                setError(response.error);
            }
        }).catch((error) => {
            setError(error);
        });
    }

    const a = get("letter", auth).then((response) => {
        if (response.body) {
            setIntro(response.body);
        }
    });

    const b = get("CVData", auth).then((response) => {
        if (response) {
            setCVData(response);
        }
    });

    Promise.all([a, b]).then(() => {
        setFinishedLoading(true);
    });
}


function getAuthFromUrl(): string {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('auth') || "";
}

export { requestAll as default, CVData , ExperienceData };