function Subsection(props: { name: string, skills: Array<string> }) {
    return (
        <div className="subsection">
            <h2>{props.name}</h2>
            <hr />
            <div className="skill-list">
                {props.skills.map((skill: string) => {
                    return <div key={skill} className="skill">
                        {skill}
                    </div>
                })}
            </div>
        </div>
    );
}

export default function Skills(props: { skills: { [name: string]: Array<string> } }) {
    return <div className="skills">
        {Object.entries(props.skills).map((entry) => {
            const [name, skills] = entry;
            return <Subsection key={name} name={name} skills={skills} />
        })}
    </div>
}