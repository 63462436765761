import { ExperienceData } from "../../api/company";
import ExperienceItem from "./ExperienceItem";

export default function Experience(props: { experience: Array<ExperienceData> }) {

    return <div className="experience">
        <h2>Praktische Erfahrung</h2>
        <hr />
        {props.experience.map((item, key) => <ExperienceItem key={key} data={item} />)}
    </div>
}