import "../../styles/Topbar.scss";

import { MdDarkMode, MdOutlineLightMode, MdPictureAsPdf } from "react-icons/md";
import ColorMode from "../../ColorMode";
import { ColorAndSetter } from "../../Wrapper";

function Topbar(props: ColorAndSetter & { pdf: string | undefined }) {
    function switchColorMode() {
        switch (props.colorMode) {
            case ColorMode.DARK:
                props.setColorMode(ColorMode.LIGHT);
                break;
            default:
            case ColorMode.LIGHT:
                props.setColorMode(ColorMode.DARK);
                break;
        }
    }
    return <div className="topbar">
        <button type="button" onClick={switchColorMode} aria-label={"Currently in"+props.colorMode}>
            {props.colorMode === ColorMode.DARK? <MdDarkMode />: <MdOutlineLightMode /> }
        </button>
        <button type="button" onClick={props.pdf?()=>window.open("https://asam.dev"+props.pdf, "_blank"):()=>alert("Leider momentan nicht möglich.")} aria-label="Print PDF">
            <MdPictureAsPdf />
        </button>
    </div>;
}

export default Topbar;