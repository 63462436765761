import { Outlet } from "react-router-dom";
import ColorMode from "./ColorMode";
import Sidebar from "./components/general/Sidebar";
import Topbar from "./components/general/Topbar";

import "./styles/Content.scss";

class ColorAndSetter {
    setColorMode: (colorMode: ColorMode) => void = () => { };
    colorMode: ColorMode = ColorMode.DARK;
}

export default function Wrapper(props: ColorAndSetter & { image: string | undefined } & { pdf: string | undefined }) {
    return <>
        <Sidebar image={props.image} />
        <div className="content-wrapper">
            <Topbar setColorMode={props.setColorMode} colorMode={props.colorMode} pdf={props.pdf}/>
            <div className="content">
                <Outlet />
            </div>
        </div>
    </>;
}

export { ColorAndSetter };