
import { MdMail, MdPinDrop, MdHome } from "react-icons/md";

function age(): number {
    const birthday = new Date("1998-01-16");
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export default function Introduction(props: { address: string }) {
    return <div className="introduction">
        <h1 className="name">Maximilian Asam</h1>
        <h2 className="subtitle">Software Engineer / Student</h2>
        <hr />
        <p className="description">
            Hello World! Mein Name ist Maximilian und ich bin {age()} Jahre alt. Zusätzlich zu meiner beruflichen Erfahrung in der <b>Webentwicklung</b>, arbeite ich privat und in meinem Informatik-Studium auch an diversen anderen Projekten (z.B. <b>Deep Learning</b>, <b>Native App Development</b>).
        </p>
        <div className="stats">
            <div className="stat"><MdMail size={24} /> <div className="text">: max@asam.dev</div></div>
            <div className="stat"><MdPinDrop size={24} /> <div className="text">: {props.address.split('\n').map((item, key) => {
                return <span key={key}>{item}<br />&nbsp;&nbsp;</span>;
            })}</div></div>
            <div className="stat"><MdHome size={24} /> <div className="text">: asam.dev</div></div>
        </div>
    </div>;
}