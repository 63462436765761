import { useEffect } from "react";

export default function Intro(props: { content: string }) {

  useEffect(() => {
    document.title = "Maximilian Asam - Bewerbung";
  }, []);
  // {props.content} 

  return (
    <div className="intro">
      <h1>Bewerbung</h1>
      <hr />
      <div className="intro-content">
        {props.content.split("\n").map((line, i) => (
          <p key={i}>{line}</p>
        ))}
      </div>
    </div>
  );
}
