import { useEffect } from "react";
import { CVData } from "../api/company";
import Education from "../components/cv/Education";
import Experience from "../components/cv/Experience";
import Introduction from "../components/cv/Introduction";
import Skills from "../components/cv/Skills";


export default function CV(props: { data: CVData|null }) {

  useEffect(() => {
    document.title = "Maximilian Asam - Lebenslauf"
  }, []);

  if (!props.data) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cv">
      <div className="mainSection">
        <Introduction address={props.data.address}/>
        <Experience experience={props.data.experience}/>
        <Education education={props.data.education}/>
      </div>
      <div className="sideSection">
        <Skills skills={props.data.skills} />
      </div>
    </div>
  );
}
