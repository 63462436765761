import { Fragment, useEffect, useState } from "react";
import ExternalLink from "../components/ExternalLink";

export default function Contact(props: { address: string | null | undefined }) {

  const [address, setAddress] = useState<JSX.Element | null>(null);

  useEffect(() => {
    if (props.address) {
      // replace \n with <br/>
      setAddress(<div className="contact-address">{props.address.split("\n").map((val,key) => <Fragment key={key}>{val}<br /></Fragment>)}</div>);

    }
  }, [props.address]);


  useEffect(() => {
    document.title = "Maximilian Asam - Impressum"
  }, []);

  return (
    <div className="contact">
      <h1>Kontakt</h1>
      <hr />
      Da es sich um eine private Webseite handelt, ist diese nicht von der sogennanten "Anbieterkennzeichnung" (im Sinne der § 5 TMG sowie § 55 RStV) betroffen.<br />
      Bei allen folgenden Angaben handelt es sich damit um eine freiwillige unverbindliche Angabe.<br />
      <br />
      Maximilian Asam<br />
      {address}<br/>
      Sie erreichen mich am besten unter meiner E-Mail-Adresse: <ExternalLink href="mailto:max@asam.dev">max@asam.dev</ExternalLink><br/>
      Wenn erwünscht kann ich Sie auch gerne anrufen.
    </div>
  );
}
